import React, {Component} from 'react';
import {Transition} from 'react-transition-group';

const INITIAL_STATE = {
	active: true,
	isNight: false,
};

const TRANSITION_DURATION = 2000;

const UNDERLAY_DEFAULT_STYLE = {
	transition: `opacity ${TRANSITION_DURATION / 3 * 2}ms ease-in-out ${TRANSITION_DURATION / 3}ms`,
	opacity: 0,
};

const UNDERLAY_TRANSITION_STYLES = {
	entering: {opacity: 1},
	entered:  {opacity: 1},
	exiting:  {opacity: 0},
	exited:   {opacity: 1},
};

const VIDEO_DEFAULT_STYLE = {
	transition: `opacity ${TRANSITION_DURATION / 3}ms ease-in-out`,
	opacity: 0,
};

const VIDEO_TRANSITION_STYLES = {
	entering: {opacity: 0},
	entered:  {opacity: 1},
	exiting:  {opacity: 0},
	exited:   {opacity: 0},
};

export default class IntroVideo extends Component {
	constructor() {
		super();

		this.state = {...INITIAL_STATE};
	}

	componentDidMount = () => {
		this.initVideo();
	}

	initVideo = () => {
		const now = new Date();
		const hours = now ? now.getHours() : 0;

		if (hours < 9 || hours >= 17) {
			this.setState({isNight: true});
		}
	}

	onClick = () => {
		this.setState({active: false});
	}

	render() {
		const {
			active,
			isNight,
		} = this.state;

		const {
			src,
			nightSrc,
		} = this.props;

		return (
			<Transition
				appear
				mountOnEnter
				unmountOnExit
				timeout={{
					enter: 100,
					exit: TRANSITION_DURATION
				}}
				in={active}>{state => (
				<div
					style={{
						...UNDERLAY_DEFAULT_STYLE,
						...UNDERLAY_TRANSITION_STYLES[state],
					}}
					className="intro">
					{isNight ? (
						<video
							key="night"
							style={{
								...VIDEO_DEFAULT_STYLE,
								...VIDEO_TRANSITION_STYLES[state],
							}}
							onClick={this.onClick}
							muted
							playsInline
							autoPlay
							loop>
							<source
								type="video/mp4"
								src={nightSrc}
							/>
						</video>
					) : (
						<video
							key="day"
							style={{
								...VIDEO_DEFAULT_STYLE,
								...VIDEO_TRANSITION_STYLES[state],
							}}
							onClick={this.onClick}
							muted
							playsInline
							autoPlay
							loop>
							<source
								type="video/mp4"
								src={src}
							/>
						</video>
					)}
				</div>
			)}</Transition>
		);
	}
}
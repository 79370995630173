import React, {Component} from 'react';
import classNames from 'classnames';
import {connectToStore} from './Provider';
import format from 'date-fns/format';

const INITIAL_STATE = {
	showColon: false,
};

class Main extends Component {
	constructor() {
		super();

		this.state = {...INITIAL_STATE};
	}

	componentDidMount = () => {
		this.interval = setInterval(this.toggleColon, 1000);
	}

	componentWillUnmount = () => {
		clearInterval(this.interval);
	}

	toggleColon = () => {
		this.setState({showColon: !this.state.showColon});
	}

	render() {
		const {showColon} = this.state;
		const {
			title,
			openTakeover,
		} = this.props;
		const now = new Date();

		return (
			<React.Fragment>
				<h1
					className="main__tl fw--600 p2">
					{title}
				</h1>
				<button
					onClick={openTakeover}
					title="Info"
					className="main__tr db p2">
					Info
				</button>
				<p className="main__bl p2">
					<span>{format(now, 'h')}</span>
					<span className={classNames({'o0': !showColon})}>:</span>
					<span>{format(now, 'mm')}</span>
					<span> {format(now, 'a')}</span>
				</p>
			</React.Fragment>
		);
	}
}

const mapStateToProps = () => ({});

const mapStoreToProps = ({
	openTakeover,
}) => ({
	openTakeover,
});

export default connectToStore(mapStateToProps, mapStoreToProps)(Main);
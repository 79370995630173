import React, {Component} from 'react';
import classNames from 'classnames';
import {connectToStore} from './Provider';
import format from 'date-fns/format';
import BlockContent from '@sanity/block-content-to-react';

const INITIAL_STATE = {
	showColon: false,
	switchState: null,
};

class Takeover extends Component {
	constructor() {
		super();

		this.state = {...INITIAL_STATE};
	}

	componentDidMount = () => {
		this.interval = setInterval(this.toggleColon, 1000);
	}

	componentWillUnmount = () => {
		clearInterval(this.interval);
	}

	toggleColon = () => {
		this.setState({showColon: !this.state.showColon});
	}

	toggleSwitch = () => {
		const {switchState} = this.state;

		if (switchState === null || switchState === 'on') {
			this.setState({switchState: 'off'});
		} else {
			this.setState({switchState: 'on'});
		}
	}

	render() {
		const {
			showColon,
			switchState,
		} = this.state;
		const {
			closeTakeover,
			isTakeoverOpen,
			content,
		} = this.props;

		if (!isTakeoverOpen) return (
			<link
				rel="preload"
				href="/images/on.png"
				as="image"
			/>
		);

		const now = new Date();
		let image = '/images/on.png';

		if (switchState === 'on') {
			image = '/images/off-on-compressed.gif';
		} else if (switchState === 'off') {
			image = '/images/on-off-compressed.gif';
		}

		return (
			<div
				className={classNames('takeover', {
					invert: switchState === 'off'
				})}>
				<link
					rel="preload"
					href="/images/off-on-compressed.gif"
					as="image"
				/>
				<link
					rel="preload"
					href="/images/on-off-compressed.gif"
					as="image"
				/>
				<div
					className="main__tl p2 rich-text pr8">
					<BlockContent blocks={content} />
				</div>
				<button
					onClick={closeTakeover}
					title="Info"
					className="main__tr db button--exit">
					EXIT
				</button>
				<button
					name="toggle"
					onClick={this.toggleSwitch}
					className="db takeover__switch">
					<img
						alt="toggle"
						className="db"
						src={image}
					/>
				</button>
				<p className="main__bl p2">
					<span>{format(now, 'h')}</span>
					<span className={classNames({'o0': !showColon})}>:</span>
					<span>{format(now, 'mm')}</span>
					<span> {format(now, 'a')}</span>
				</p>
			</div>
		);
	}
}

const mapStateToProps = ({
	isTakeoverOpen
}) => ({
	isTakeoverOpen
});

const mapStoreToProps = ({
	closeTakeover,
}) => ({
	closeTakeover,
});

export default connectToStore(mapStateToProps, mapStoreToProps)(Takeover);
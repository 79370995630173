import React, {Component} from 'react';
import BlockContent from '@sanity/block-content-to-react';
import reduce from 'lodash/reduce';
import {Transition} from 'react-transition-group';
import repeatArray from '../utils/repeatArray';

const CAROUSEL_COPIES = 6;

const WHEEL_THRESHOLD = 50;

const INITIAL_STATE = {
	projectIndex: 0,
	slideIndexByProjectIndex: [0],
	verticalTransition: null,
	horizontalTransition: null,
};

const TRANSITION_DURATION = 600;

const DEFAULT_STYLE = {
	transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(0.25, 0.5, 0.5, 1)`,
	transform: 'translate3d(0, 0, 0)'
};

const STYLES = {
	UP: {
		entering: {transform: 'translate3d(0,  100%, 0)'},
		entered:  {transform: 'translate3d(0,  0,    0)'},
		exiting:  {transform: 'translate3d(0, -100%, 0)'},
		exited:   {transform: 'translate3d(0, -100%, 0)'},
	},
	DOWN: {
		entering: {transform: 'translate3d(0, -100%, 0)'},
		entered:  {transform: 'translate3d(0,  0,    0)'},
		exiting:  {transform: 'translate3d(0,  100%, 0)'},
		exited:   {transform: 'translate3d(0,  100%, 0)'},
	},
	LEFT: {
		entering: {transform: 'translate3d( 100vw, 0, 0)'},
		entered:  {transform: 'translate3d( 0,     0, 0)'},
		exiting:  {transform: 'translate3d(-100vw, 0, 0)'},
		exited:   {transform: 'translate3d(-100vw, 0, 0)'},
	},
	RIGHT: {
		entering: {transform: 'translate3d(-100vw, 0, 0)'},
		entered:  {transform: 'translate3d( 0,     0, 0)'},
		exiting:  {transform: 'translate3d( 100vw, 0, 0)'},
		exited:   {transform: 'translate3d( 100vw, 0, 0)'},
	}
};

export default class ProjectCarousel extends Component {
	constructor(props) {
		super();

		// Repeat projects/slides to avoid repeat rendering issues
		const {projects = []} = props;
		const repeatAssetProjects = projects.map(p => ({
			...p,
			assets: repeatArray(p.assets || [], CAROUSEL_COPIES),
		}));
		const repeatProjects = repeatArray(repeatAssetProjects, CAROUSEL_COPIES);

		this.state = {
			...INITIAL_STATE,
			slideIndexByProjectIndex: repeatProjects.map(p => 0),
			projects: repeatProjects,
		};
	}

	componentDidMount = () => {
		window.addEventListener('wheel', this.onWheel, {passive: false});
		window.addEventListener('keydown', this.onKeyDown, {passive: false});
	}

	componentWillUnmount = () => {
		window.removeEventListener('wheel', this.onWheel, {passive: false});
		window.removeEventListener('keydown', this.onKeyDown, {passive: false});
	}

	onKeyDown = event => {
		if (event.keyCode == '38') {
			this.previousProject();
		}
		else if (event.keyCode == '40') {
			this.nextProject();
		}
		else if (event.keyCode == '37') {
			this.previousSlide(this.state.projectIndex);
		}
		else if (event.keyCode == '39') {
			this.nextSlide(this.state.projectIndex);
		}
	}

	onWheel = event => {
		if (this.wheelActive) return;

		if (event.deltaY >= WHEEL_THRESHOLD) {
			this.nextProject();

			// Prevent further wheel events
			this.wheelActive = true;
			this.wheelTimeout = setTimeout(() => {
				this.wheelActive = false;
			}, TRANSITION_DURATION);
		} else if (event.deltaY < -WHEEL_THRESHOLD) {
			this.previousProject();

			// Prevent further wheel events
			this.wheelActive = true;
			this.wheelTimeout = setTimeout(() => {
				this.wheelActive = false;
			}, TRANSITION_DURATION);
		}

		return false;
	}

	nextProject = () => {
		clearTimeout(this.verticalTransitionTimeout);

		if (this.state.projectIndex >= this.state.projects.length - 1) {
			this.setState({
				projectIndex: 0,
				verticalTransition: 'NEXT',
			});
		} else {
			this.setState({
				projectIndex: this.state.projectIndex + 1,
				verticalTransition: 'NEXT',
			});
		}

		this.verticalTransitionTimeout = setTimeout(() => {
			this.setState({
				verticalTransition: null
			});
		}, TRANSITION_DURATION);
	}

	previousProject = () => {
		clearTimeout(this.verticalTransitionTimeout);

		if (this.state.projectIndex <= 0) {
			this.setState({
				projectIndex: this.state.projects.length - 1,
				verticalTransition: 'PREVIOUS',
			});
		} else {
			this.setState({
				projectIndex: this.state.projectIndex - 1,
				verticalTransition: 'PREVIOUS',
			});

		}
		
		this.verticalTransitionTimeout = setTimeout(() => {
			this.setState({
				verticalTransition: null
			});
		}, TRANSITION_DURATION);
	}

	nextSlide = projectIndex => {
		clearTimeout(this.horizontalTransitionTimeout);
		
		const project = this.state.projects[projectIndex];
		const {assets = []} = project;
		const currentSlideIndex = this.state.slideIndexByProjectIndex[projectIndex];
		const updatedSlideIndexByProjectIndex = [...this.state.slideIndexByProjectIndex];

		let updatedSlideIndex = currentSlideIndex;

		if (currentSlideIndex >= assets.length - 1) {
			updatedSlideIndex = 0;
		} else {
			updatedSlideIndex++;
		}

		updatedSlideIndexByProjectIndex[projectIndex] = updatedSlideIndex;

		this.setState({
			slideIndexByProjectIndex: updatedSlideIndexByProjectIndex,
			horizontalTransition: 'NEXT',
		});

		this.horizontalTransitionTimeout = setTimeout(() => {
			this.setState({
				verticalTransition: null
			});
		}, TRANSITION_DURATION);
	}

	previousSlide = projectIndex => {
		clearTimeout(this.horizontalTransitionTimeout);
		
		const project = this.state.projects[projectIndex];
		const {assets = []} = project;
		const currentSlideIndex = this.state.slideIndexByProjectIndex[projectIndex];
		const updatedSlideIndexByProjectIndex = [...this.state.slideIndexByProjectIndex];

		let updatedSlideIndex = currentSlideIndex;

		if (currentSlideIndex <= 0) {
			updatedSlideIndex = assets.length - 1;
		} else {
			updatedSlideIndex--;
		}

		updatedSlideIndexByProjectIndex[projectIndex] = updatedSlideIndex;

		this.setState({
			slideIndexByProjectIndex: updatedSlideIndexByProjectIndex,
			horizontalTransition: 'PREVIOUS',
		});

		this.horizontalTransitionTimeout = setTimeout(() => {
			this.setState({
				verticalTransition: null
			});
		}, TRANSITION_DURATION);
	}

	render() {
		const {
			projectIndex,
			slideIndexByProjectIndex,
			verticalTransition,
			horizontalTransition,
			projects,
		} = this.state;

		// Get all assets, not repeats in state
		const allAssets = reduce(this.props.projects, (assets, project) => {
			if (project.assets) {
				return [
					...assets,
					...project.assets,
				];
			}

			return assets;
		}, []);

		const projectRows = projects.map((project, rowIndex) => {
			const slideIndex = slideIndexByProjectIndex[rowIndex];
			const {assets = []} = project;
			const active = rowIndex === projectIndex;
			let currentTransition = {};

			if (verticalTransition === 'NEXT') {
				currentTransition = STYLES.UP;
			} else if (verticalTransition === 'PREVIOUS') {
				currentTransition = STYLES.DOWN;
			}

			const assetColumns = assets.map((asset, columnIndex) => {
				const active = columnIndex === slideIndex;
				let currentTransition = {};

				if (horizontalTransition === 'NEXT') {
					currentTransition = STYLES.LEFT;
				} else if (horizontalTransition === 'PREVIOUS') {
					currentTransition = STYLES.RIGHT;
				}

				return (
					<Transition
						key={`${columnIndex}_${asset._key}`}
						in={active}
						mountOnEnter
						unmountOnExit
						timeout={{
							enter: 10,
							exit: TRANSITION_DURATION / 3 * 2
						}}>{state => (
						<img
							style={{
								...DEFAULT_STYLE,
								...currentTransition[state],
							}}
							className="db project__image"
							src={`${asset.url}?w=1200&h=1200&fit=max&q=75&auto=format`}
						/>
					)}</Transition>
				);
			});

			return (
				<Transition
					key={`${rowIndex}_${project._id}`}
					in={active}
					mountOnEnter
					unmountOnExit
					timeout={{
						enter: 10,
						exit: TRANSITION_DURATION / 3 * 2
					}}>{state => (
					<div
						style={{
							...DEFAULT_STYLE,
							...currentTransition[state],
						}}
						className="project fx fx--col fx--around tc">
						{assetColumns}
						<div className="project__title tr pl10">
							<h2>
								{project.title}
							</h2>
							<BlockContent
								blocks={project.description}
							/>
						</div>
					</div>
				)}</Transition>
			);
		});

		const assetPreloaders = allAssets.map(asset => (
			<link
				key={asset._key}
				rel="preload"
				href={`${asset.url}?w=1200&h=1200&fit=max&q=75&auto=format`}
				as="image"
			/>
		));
		
		return (
			<React.Fragment>
				{assetPreloaders}
				{projectRows}
				<div className='project__nav'>
					<button
						className="db project__nav__previous-project"
						onClick={this.previousProject}
						name="previous"
					/>
					<button
						className="db project__nav__next-project"
						onClick={this.nextProject}
						name="next"
					/>
					<button
						className="db project__nav__previous-slide"
						onClick={() => this.previousSlide(projectIndex)}
						name="previous"
					/>
					<button
						className="db project__nav__next-slide"
						onClick={() => this.nextSlide(projectIndex)}
						name="next"
					/>
				</div>
			</React.Fragment>
		);
	}
}
export default (array, times = 1) => {
	let newArray = [];

	for (let i = 0; i < times; i++) {
		newArray = [
			...newArray,
			...array,
		];
	}

	return newArray;
};